<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-8">
    <div class="col-xl-12">
      <div class="card mb-5 mb-xl-8">
        <!--begin::Body-->
        <div class="card-body pt-5">
          <!--begin::Heading-->
          <div class="text-center">
            <!--begin::Title-->
            <div class="card-label fw-bolder fs-2 mb-3">
              Sürüm Güncellemeleri
            </div>
            <!--end::Title-->
          </div>
          <div class="fs-2"><b>V1.1.6</b> - 19.07.2024</div>
          <span>
            - Landing page aktif hale getirildi.
            <br />
            - Create Company ekranı oluşturuldu.
            <br />
            - Tasarım ve yönlendirme tarafında hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.5</b> - 18.07.2024</div>
          <span>
            - Aktif modüller menüsü oluşturuldu.
            <br />
            - Satın alınan modüller servisi eklendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.4</b> - 17.07.2024</div>
          <span>
            - Dil dosyaları güncellendi.
            <br />
            - Otel asistanı modül sayfasına personel ekleme butonu eklendi. 
            <br />
            - Kullanıcı rolu kapsamında tarayıcıda değiştirilme sonucu oluşan hata giderildi. 
            <br />
            - Şirket listeleme ekranı eklendi.
            <br />
            - Modül listeleme ekranında mobil cihazlarda oluşan tasarım hatası giderildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.3</b> - 11.07.2024</div>
          <span>
            - Dashboard ekranında istatistik servisi entegre edildi.
            <br />
            - Modül detay ekranına dil desteği eklendi.
            <br />
            - Otel asistanı modülünde birden fazla konuşma geçmişi görüntüleme esnasında oluşan hata giderildi.
            <br />
            - Tenant değiştirme fonksiyonunda aynı tenanta tekrar geçiş yapabilmeyi sağlayan ve sayfayı refreshleyen hata düzeltildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.2</b> - 09.07.2024</div>
          <span>
            - Modül detay ekranına modülün yapay zeka modülünü seçme componenti eklendi.
            <br />
            - Modül detay ekranı tasarım düzeltmeleri yapıldı.
            <br />
            - Ai asistan modülü genel tasarım düzeltmeleri yapıldı.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.1</b> - 05.07.2024</div>
          <span>
            - Yetkiler güncellendi.
            <br />
            - OTP kodu girme kısmını güncellendi, kontrollü giriş eklendi. 
            <br />
            - Otel asistanı modülünde personel listesine row-click ekledi.
            <br />
            - Otel asistanı modülünde yeni sohbet oluşturulduğunda kullanıcının duygu analizinin ekranda gösterilmesi sağlandı.
            <br />
            - Profilim ekranına ülke kodu eklendi, validasyonlar düzeltildi.
            <br />
            - Personel silme fonksiyonu aktif hale getirildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.1.0</b> - 04.07.2024</div>
          <span>
            - Proje ana yapısı ve akışı güncellendi. 
            <br />
            - Otel asistanı modülü konuşma geçmişlerine duygu analizi eklendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.13</b> - 01.07.2024</div>
          <span>
            - Modüller sayfası ve modül detay sayfası güncellendi.
            <br />
            - Landing page güncellendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.12</b> - 27.06.2024</div>
          <span>
            - Modül listesi ekranı fonksiyonel ve modüler hale getirildi. 
            <br />
            - Modül detay sayfası servise bağlanarak güncellendi.
            <br />
            - Landing page router problemi giderildi.
            <br />
            - Landing page güncellendi.
            <br />
            - Şirket değiştirme fonksiyonunda yaşanılan yetki problemi giderildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.11</b> - 25.06.2024</div>
          <span>
            - Kullanılmayan ve fonksiyonel olarak çalışmayan ekranlar menüden kaldırıldı.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.10</b> - 12.06.2024</div>
          <span>
            - Landing Page eklendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.9</b> - 11.06.2024</div>
          <span>
            - Menülerin tamamı aktif hale getirildi.
            <br />
            - Change Tenant fonksiyonu eklendi.
            <br />
            - AI Otel Asistanı admin rolü alt menüleri eklendi.
            <br />
            - AI Otel Asistanı modülü personellerin session kayıt geçmişin
            görüntüleme ekranı oluşturuldu.
            <br />
            - Kullanıcı menüsünde telefon numarasına ülke kodu eklendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.8</b> - 31.05.2024</div>
          <span>
            - Site demo olarak hazırlanıp düzeltmeler yapıldı.
            <br />
            - AI Otelcilik Asistanında beğenmedim butonu eklenerek yeniden
            generate edilmesi sağlandı.
            <br />
            - Otelcilik Asistan modülünde arama fonksiyonu geliştirildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.7</b> - 30.05.2024</div>
          <span>
            - Genel sayfa eklemesi ve düzeltmeleri yapıldı.
            <br />
            - Şirket oluştur sayfası eklendi.
            <br />
            - Şirket listeleme sayfası eklendi.
            <br />
            - Personel ekle ve personel listesi ekranları çalışır hale
            getirildi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.6</b> - 27.05.2024</div>
          <span>
            - Ogza rolündeki tüm modüller ekranı ve modül oluştur/düzenle ekranı
            eklendi.
            <br />
            - Chatgpt ekranı eklendi.
            <br />
            - İngilizce ve Türkçe Dil desteği eklendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.5</b> - 23.05.2024</div>
          <span>
            - Kullanıcı rolü anket ve mülakat modülleri giriş ekranı eklendi.
            <br />
            - Misafir rolü layoutu oluşturuldu. Mülakat ve anket ekranları
            eklendi.
            <br />
            - Menüde Ogza kategorisi alt menüleri oluşturuldu.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.4</b> - 20.05.2024</div>
          <span>
            - Kullanıcı menü kategorisine Mülakat eklendi.
            <br />
            - Kullanıcı anket arayüz tasarımı güncellendi ve mülakat ekranı
            oluşturuldu.
            <br />
            - Form ve validasyonlar güncellendi.
            <br />
            - Menü ikonları güncellendi.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.3</b> - 17.05.2024</div>
          <span>
            - Kullanıcı menü kategorisi oluşturuldu.
            <br />
            - Kullanıcı anket arayüz tasarımı eklendi.
            <br />
            - Admin rolüne ait Personeller, Personel Ekle, Hesap Durumu, Hesap
            Türleri ekranları oluşturuldu.
            <br />
            - Kullanıcıdan giriş istenilen inputlarda validasyonlar tanımlandı.
            <br />
            - Tasarımsal hatalar giderildi.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.2</b> - 16.05.2024</div>
          <span>
            - Mülakat ve Anketör için modül detay ekranları oluşturuldu.
            <br />
            - Menü düzenlendi, anketör alt menüleri eklendi.
            <br />
            - Anketör Oluştur ve Mülakat Oluştur ekranları eklendi.
            <br />
            - Tasarımsal düzenlemeler yapıldı.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.1</b> - 15.05.2024</div>
          <span>
            - Modül ekranları eklendi.
            <br />
            - Modül detay ekranı eklendi.
            <br />
            - Otelcilik modülü mesaj onay bildirimi kaldırıldı.
            <br />
            - Tasarımsal düzenlemeler yapıldı.
          </span>
          <br />
          <br />
          <div class="fs-4"><b>V1.0.0</b> - 14.05.2024</div>
          <span>
            - Website şablonu hazırlanmış olup giriş ekranları tamamlanmıştır.
            <br />
            - Kullanıcı profil düzenleme ekranları yenilenmiştir.
            <br />
            - Sürüm güncellemeleri ekranı oluşturulmuştur.
            <br />
            - Menü tasarlanmıştır.
            <br />
            - Şablon üzerinde renk, font ve tasarım güncellemeleri yapılmıştır.
            <br />
            - Otelcilik modülü ekran tasarımları tamamlanıp aktif hale
            getirilmiştir.
          </span>
        </div>
        <!--end::Body-->
      </div>
    </div>
  </div>
  <!--end::Row-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";

export default defineComponent({
  name: "versions",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Versiyonlar");
    });

    return {};
  },
});
</script>
