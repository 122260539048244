
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";

export default defineComponent({
  name: "versions",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Versiyonlar");
    });

    return {};
  },
});
